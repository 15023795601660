import React from 'react';
import { colors } from 'config/colors';
import { Col, Row } from 'reactstrap';
import { ReactComponent as XIcon } from 'assets/images/icons/XIcon.svg';
import { ReactComponent as DeleteIcon } from 'assets/images/icons/DeleteIcon.svg';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    fontWeight: '600',
    fontSize: '1.6em',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    border: `1px solid ${colors.lightGrey}`,
    borderRadius: '6px'
  },
  keysWrapper: {
    width: '50%'
  },
  key: {
    padding: '40px'
  }
};

export const Numpad = ({ handleChange }) => {
  const keys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '0', '#'];

  return(
    <div style={styles.container} className="py-4">
      <Row style={styles.keysWrapper}>
        {keys.map((item, i) => 
          <Col
            xs="4"
            key={i}
            style={styles.key}
            onClick={() => handleChange(item)}
          >
            {item === '*' ? <XIcon height="20" width="20"/> : item === '#' ? <DeleteIcon height="20" width="20"/> : item}
          </Col>
        )}
      </Row>  
    </div>
  );
};