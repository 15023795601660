import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { useNavigate } from 'react-router';
import { TransitionView } from 'components/common/transition-view';
import { useRouterTransition } from 'hooks/useRouterTransition';
import { useIntl } from 'react-intl';
import { MenuButton } from 'components/common/MenuButton';
import { PrimaryButtonIcon } from 'components/common/PrimaryButtonIcon';
import { ReactComponent as AcessoFacil } from 'assets/images/icons/AcessoFacil.svg';
import { ReactComponent as Lokker } from 'assets/images/icons/Lokker.svg';
import { ReactComponent as Frio } from 'assets/images/icons/Frio.svg';
import api from 'helpers/api';
import { LoadingModal } from 'components/common/LoadingModal';
import { disabledOpacity } from 'config/appConfig';
import { SecondaryNav } from 'components/common/SecondaryNav';

const styles = {
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '0px',
    marginTop: '40px',
    marginBottom: '40px',
  },
  text: {
    fontSize: '3.2vw',
    fontWeight: '600',
    textAlign: 'left'
  },
  goBackText: {
    textDecoration: 'underline',
    textAlign: 'right'
  },
  textLeft: {
    textAlign: 'left'
  },
  textRight: {
    textAlign: 'right'
  },
  accessibleLocker: {
    alignItems: 'end'
  },
  sizeText: {
    fontWeight: '600',
    fontSize: '1.6em',
    width: 'fit-content',
  }, 
};

export const YellowZoneLokkerType = () => {
  const t = useIntl();
  let navigate = useNavigate();
  let { transitionType, handleTransition } = useRouterTransition(navigate);
  
  const [loading, setLoading] = useState(false);
  const [lockers, setLockers] = useState([]);

  const normalLockersAvailable = () => {
    return lockers.filter(function (locker) {
      return locker.enabled === 1 && locker.status === 0 && locker.locker_metra_status === 2 && locker.lockerType === 0;
    });
  };

  const coldLockersAvailable = () => {
    return lockers.filter(function (locker) {
      return locker.enabled === 1 && locker.status === 0 && locker.locker_metra_status === 2 && locker.lockerType === 1;
    });
  };

  const navTo = (url, state) =>{
    handleTransition(url, state)
  };

  useEffect(() => {
    setLoading(true);
    api.get(`${process.env.REACT_APP_API_URL}api/mar-shopping/lockersByPlace/${process.env.REACT_APP_LOCATION_ID}`)
      .then(function (response) {
        console.log(response);
        if (response?.data?.ResultCode === 1) {
          setLockers(response?.data?.Data?.Lockers)
        } else {
          console.log(response?.data?.Result);
        }
      })
      .catch(function (err) {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  },[]);

  return(
    <TransitionView animation="fade-down" transitionType={transitionType} className="position-relative">
      <LoadingModal isLoading={loading} /> 
      <SecondaryNav secNavText="app.pickLockerType" navToURL="/yellow-zone" />
      <Row className="m-0">
        <MenuButton
          text={t.messages['app.lockerTypeNormal']}
          subtext={`${normalLockersAvailable().length} ${t.messages['app.availableLockers']}`}
          icon={<Col className="d-flex flex-column justify-content-end align-items-end">
            <Row className="mb-3">
              <Lokker height={34} className="p-0" style={{opacity: normalLockersAvailable().length !== 0 ? '1' : disabledOpacity}}/>
            </Row>
            <Row className="d-flex flex-row justify-content-center align-items-center">
              <span style={{...styles.sizeText, opacity: normalLockersAvailable().length !== 0 ? '1' : disabledOpacity}}>S</span>
              <span style={{...styles.sizeText, opacity: normalLockersAvailable().length !== 0 ? '1' : disabledOpacity}}>M</span>
              <span style={{...styles.sizeText, opacity: normalLockersAvailable().length !== 0 ? '1' : disabledOpacity}}>L</span>
            </Row>
          </Col>}
          onClick={() => navTo('/yellow-zone/lokker-size', { state: { lockers: lockers, type: 0, accessibility: 0 } })}
          disabled={normalLockersAvailable().length !== 0 ? false : true}
        />
      </Row>

      <Row className="m-0">
        <MenuButton
          text={t.messages['app.lockerTypeCold']}
          subtext={`${coldLockersAvailable().length} ${t.messages['app.availableLockers']}`}
          icon={<Col className="d-flex flex-column justify-content-end align-items-end">
            <Row className="mb-3">
              <Frio height={34} className="p-0" style={{opacity: coldLockersAvailable().length !== 0 ? '1' : disabledOpacity}}/>
            </Row>
            <Row className="d-flex flex-row justify-content-center align-items-center">
              <span style={{...styles.sizeText, opacity: coldLockersAvailable().length !== 0 ? '1' : disabledOpacity}}>M</span>
            </Row>
          </Col>}
          onClick={() => navTo('/yellow-zone/lokker-size', { state: { lockers: lockers, type: 1, accessibility: 0 } })}
          disabled={coldLockersAvailable().length !== 0 ? false : true}
        />
      </Row>

      <Row style={styles.accessibleLocker} className="h-100 pb-4 m-0">
        <div className="m-0 p-0">
          <PrimaryButtonIcon
            text={t.messages['app.accessibleLockers']}
            onClick={() => navTo('/yellow-zone/accessible-lokker', { state: { lockers: lockers }})}
            icon={<AcessoFacil height={34} />}
          />
        </div>
      </Row>
    </TransitionView>
  )
}