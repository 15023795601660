import React from 'react';
import { Row, Col } from 'reactstrap';
import { colors } from 'config/colors';
import { FormattedMessage } from 'react-intl';

export const OpenReleaseButtons = ({ releaseLokker, openLokker, disabled }) => {

  const styles = {
    leftColumn: {
      padding: '0 5px 0 0'
    },
    rightColumn: {
      padding: '0 0 0 5px'
    },
    paddingButton: {
      padding: '2.3vw 0 2.3vw 0'
    },
    secondaryButton: {
      backgroundColor: colors.white,
      border: `1px solid ${colors.lightGrey}`,
      borderRadius: '6px'
    },
    primaryButton: {
      backgroundColor: disabled === true ? colors.disabled : colors.yellow,
      border: `1px solid ${colors.lightGrey}`,
      borderRadius: '6px'
    },
    buttonText: {
      fontSize: '3vw',
      fontWeight: '600'
    }
  }

  return(
    <Row className="m-0 p-0">
      <Col xs="6" style={styles.leftColumn}>
        <div style={{...styles.secondaryButton, ...styles.paddingButton}} onClick={releaseLokker}>
          <span style={styles.buttonText}>
            <FormattedMessage id="app.release" />
          </span>
        </div>
      </Col>
      <Col xs="6" style={styles.rightColumn}>
        <div style={{...styles.primaryButton, ...styles.paddingButton}} onClick={openLokker}>
          <span style={styles.buttonText}>
            <FormattedMessage id="app.openLabel" />
          </span>
        </div>
      </Col>
    </Row>
  );
};