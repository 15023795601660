import React from 'react';

export const LoadingModal = ({ isLoading }) => {

  const styles = {
    container: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(250,250,250, 0.93)',
      zIndex: '1'
    },
    image: {
      transform: 'translateY(-200%)'
    }
  }

  return(
    isLoading &&
      <div style={styles.container}>
        <img style={styles.image} src="/Loading_Logo_Simple.webp" height="150" width="150"></img>
      </div>
  );
};
