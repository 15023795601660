import React, { useState } from 'react';
import { Row } from 'reactstrap';
import { useNavigate } from 'react-router';
import { TransitionView } from 'components/common/transition-view';
import { useRouterTransition } from 'hooks/useRouterTransition';
import { Numpad } from 'components/common/numpad/Numpad';
import { Input } from 'components/common/numpad/Input';
import { useIntl } from 'react-intl';
import { PrimaryButton } from 'components/common/PrimaryButton';
import api from 'helpers/api';
import { LoadingModal } from 'components/common/LoadingModal';

export const YellowZoneAccessMyLokkers = () => {
  const t = useIntl();
  let navigate = useNavigate();
  let { transitionType, handleTransition } = useRouterTransition(navigate);

  const [selectedInput, setSelectedInput] = useState('phoneNumber');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [pin, setPin] = useState('');
  const [ loading, setLoading ] = useState(false);
  const [ isSubmitting, setIsSubmitting ] = useState(false);
  const [confirmPinAnimation, setConfirmPinAnimation] = useState('');

  const invalidInputAnimationDuration = 0.7;

  const phoneNumberSize = 9;
  const pinSize = 4;

  const handleChangePhoneNumber = (val) => {
    // delete last char from phoneNumber
    if(val === '#') setPhoneNumber(phoneNumber.slice(0, -1));
    // add char to phoneNumber
    // if last char also go to next input
    else if(phoneNumber.length === phoneNumberSize - 1){
      setPhoneNumber(phoneNumber + val);
      setSelectedInput('pin');
    } 
    else if(phoneNumber.length < phoneNumberSize) setPhoneNumber(phoneNumber + val);
    else if(phoneNumber.length === phoneNumberSize) setSelectedInput('pin');
  };

  const handleChangePin = (val) => {
    // delete last char from pin
    if(val === '#'){
      if(pin.length === 0) setSelectedInput('phoneNumber');
      else setPin(pin.slice(0, -1));
    }
    // add char to pin
    // if last char also go to next input
    else if(pin.length === pinSize - 1){
      setPin(pin + val);
    }
    else if(pin.length < pinSize) setPin(pin + val);
  };

  const navTo = (url, state) =>{
    handleTransition(url, state)
  };

  const handleNumpadInput = (val) => {
    // when user presses asterisk clear everything
    if(val === '*'){
      setPhoneNumber('');
      setPin('');
      setSelectedInput('phoneNumber');
    }
    else{
      // handle specific input
      switch (selectedInput) {
        case 'phoneNumber':
          handleChangePhoneNumber(val)
          break;
        case 'pin':
          handleChangePin(val)
          break;
        default:
          break;
      }
    }
  };

  const isPhoneNumberValid = () => {
    const pattern = /\d{9}/;
    if(phoneNumber.match(pattern)){
      // is valid
      return true;
    }
  };

  const isPinValid = () => {
    const pattern = /\d{4}/;
    if(pin.match(pattern)){
      // is valid
      return true;
    }
  };

  const validateInput = () => {
    if(isPhoneNumberValid() && isPinValid()){
      // input is valid
      return true;
    } else{
      // input is invalid
      return false;
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    setIsSubmitting(true);
    api.get(`${process.env.REACT_APP_API_URL}api/mar-shopping/getLockersByNumber?locationId=${process.env.REACT_APP_LOCATION_ID}&pin=${pin}&number=${phoneNumber}`)
      .then(function (response) {
        if (response?.data?.ResultCode === 1) {
          navTo('/yellow-zone/my-lokkers', { state: { lockers: response?.data?.Lockers, phoneNumber: phoneNumber, pin: pin }});
        } else if (response?.data?.Description === 'Invalid PIN') {
          console.log(response?.data?.Description);
          setConfirmPinAnimation('shake');
          setTimeout(() => {
            setSelectedInput('phoneNumber');
            setPhoneNumber('');
            setPin('');
            setConfirmPinAnimation('');
          }, invalidInputAnimationDuration * 1000);
        } else {
          console.log('Unknow Error');
          setConfirmPinAnimation('shake');
          setTimeout(() => {
            setSelectedInput('phoneNumber');
            setPhoneNumber('');
            setPin('');
            setConfirmPinAnimation('');
          }, invalidInputAnimationDuration * 1000);
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        setConfirmPinAnimation('shake');
          setTimeout(() => {
            setSelectedInput('phoneNumber');
            setPhoneNumber('');
            setPin('');
            setConfirmPinAnimation('');
          }, invalidInputAnimationDuration * 1000);
      })
      .finally(() => {
        setLoading(false);
        setIsSubmitting(false);
      });
  };

  const goBack = () => {
    navTo('/yellow-zone');
  };

  return(
    <TransitionView animation="fade-down" transitionType={transitionType} className="justify-content-between position-relative">
      <LoadingModal isLoading={loading} />
      <Row className="m-0">
        <Input
          label={t.messages['app.insertYourPhoneNumber']}
          size={phoneNumberSize}
          type="number"
          value={phoneNumber}
          isFocused={selectedInput === 'phoneNumber'}
          style={{animation: `${confirmPinAnimation} ${invalidInputAnimationDuration}s ease-in-out`}}
        />
      </Row>
      <Row className="m-0">
        <Input
          label={t.messages['app.insertYourPin']}
          size={pinSize}
          type="password"
          value={pin}
          isFocused={selectedInput === 'pin'}
          style={{animation: `${confirmPinAnimation} ${invalidInputAnimationDuration}s ease-in-out`}}
        />
      </Row>
      <Row className="m-0">
        <Numpad handleChange={handleNumpadInput} />
      </Row>
      <Row className="m-0">
        <PrimaryButton
          text={t.messages['app.confirm']}
          onClick={handleSubmit}
          isEnabled={validateInput()}
          color="yellow"
        />
        <PrimaryButton
          text={t.messages['app.cancel']}
          onClick={goBack}
          color="white"
          outline={true}
          border="lightGrey"
        />
      </Row>
    </TransitionView>
  )
}