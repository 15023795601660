import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { useNavigate, useLocation } from 'react-router';
import { TransitionView } from 'components/common/transition-view';
import { useRouterTransition } from 'hooks/useRouterTransition';
import { FormattedMessage, useIntl } from 'react-intl';
import { MenuButton } from 'components/common/MenuButton';
import { ReactComponent as Carteira } from 'assets/images/icons/Carteira.svg';
import { ReactComponent as Mochila } from 'assets/images/icons/Mochila.svg';
import { ReactComponent as Portatil } from 'assets/images/icons/Portatil.svg';
import { ReactComponent as Capacete } from 'assets/images/icons/Capacete.svg';
import { ReactComponent as Saco } from 'assets/images/icons/Saco.svg';
import { ReactComponent as Malinha } from 'assets/images/icons/Malinha.svg';
import { ReactComponent as Compras } from 'assets/images/icons/Compras.svg';
import { ReactComponent as Mala } from 'assets/images/icons/Mala.svg';
import { ReactComponent as Encomendas } from 'assets/images/icons/Encomendas.svg';
import { disabledOpacity } from 'config/appConfig';
import { SecondaryNav } from 'components/common/SecondaryNav';
import api from 'helpers/api';
import { LoadingModal } from 'components/common/LoadingModal';
import { PrimaryButton } from 'components/common/PrimaryButton';

const styles = {
  text: {
    fontSize: '2.2vw'
  }
};

export const DeliveryZoneLokkerSize = () => {
  const t = useIntl();
  let navigate = useNavigate();
  const { state } = useLocation();
  let { transitionType, handleTransition } = useRouterTransition(navigate);
  const [loading, setLoading] = useState(false);
  const [lockers, setLockers] = useState([]);
  
  const navTo = (url, state) =>{
    handleTransition(url, state)
  };

  const normalSizeSLockersAvailable = () => {
    if(lockers) {
      return lockers.filter(function (locker) {
        return (
          locker.enabled === 1 && 
          locker.status === 0 && 
          locker.locker_metra_status === 2 &&
          locker.lockerType === 0 &&
          locker.size === 'S'
        )
      });
    }
  };

  const normalSizeMLockersAvailable = () => {
    if(lockers) {
      return lockers.filter(function (locker) {
        return (
          locker.enabled === 1 && 
          locker.status === 0 && 
          locker.locker_metra_status === 2 && 
          locker.lockerType === 0 && 
          locker.size === 'M'
        )
      });
    }
  };

  const normalSizeLLockersAvailable = () => {
    if(lockers) {
      return lockers.filter(function (locker) {
        return (
          locker.enabled === 1 && 
          locker.status === 0 && 
          locker.locker_metra_status === 2 && 
          locker.lockerType === 0 && 
          locker.size === 'L'

        )
      });
    }
  };

  useEffect(() => {
    if(state !== null) {
      setLoading(true);
      api.get(`${process.env.REACT_APP_API_URL}api/mar-shopping/getLockersByPlaceWithValidation?code=${state.code}&pin=${state.pin}&locationId=${process.env.REACT_APP_LOCATION_ID}`)
      .then(function (response) {
        console.log(response);
        if (response?.data?.ResultCode === 1) {
          setLockers(response?.data?.Data?.Lockers);
        } else {
          console.log(response?.data?.Result);
        }
      })
      .catch(function (err) {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
    }
  },[]);

  return(
    !state
    ?
    <TransitionView animation="fade-down" transitionType={transitionType} className="justify-content-center position-relative">
      <Row className="m-0">
        <span className="mb-5" style={styles.text}>
          <FormattedMessage id="app.loginWidthDataLabel" />
        </span>
        <PrimaryButton 
            text={t.messages['app.loginLabel']} 
            color="red" 
            onClick={() => navTo('/delivery-zone/colaborator-access')} 
            txtColor="light"
          />
      </Row>
    </TransitionView>
    :
    <TransitionView animation="fade-down" transitionType={transitionType} className="position-relative">
      <SecondaryNav secNavText="app.pickLockerSize" navToURL="/delivery-zone" />
      <LoadingModal isLoading={loading} />
      <Row className="m-0">
        <MenuButton
          text={t.messages['app.sizeS']}
          subtext={`${normalSizeSLockersAvailable().length} ${t.messages['app.availableLockers']}`}
          icon={
            <Row>
              <Col xs="4"><Carteira height="26" width="26" style={{opacity: normalSizeSLockersAvailable().length !== 0 ? '1' : disabledOpacity}} /></Col>
              <Col xs="4"><Malinha height="26" width="26" style={{opacity: normalSizeSLockersAvailable().length !== 0 ? '1' : disabledOpacity}} /></Col>
              <Col xs="4"><Portatil height="26" width="26" style={{opacity: normalSizeSLockersAvailable().length !== 0 ? '1' : disabledOpacity}} /></Col>
            </Row>
          }
          onClick={() => navTo('/delivery-zone/client-number', { state : { ...state, size: 'S', lockers: lockers }})}
          disabled={normalSizeSLockersAvailable().length !== 0 ? false : true}
        />
      </Row>

      <Row className="m-0">
        <MenuButton
          text={t.messages['app.sizeM']}
          subtext={`${normalSizeMLockersAvailable().length} ${t.messages['app.availableLockers']}`}
          icon={
            <Row>
              <Col xs="4"><Capacete height="30" width="30" style={{opacity: normalSizeMLockersAvailable().length !== 0 ? '1' : disabledOpacity}}/></Col>
              <Col xs="4"><Saco height="30" width="30" style={{opacity: normalSizeMLockersAvailable().length !== 0 ? '1' : disabledOpacity}}/></Col>
              <Col xs="4"><Mochila height="30" width="30" style={{opacity: normalSizeMLockersAvailable().length !== 0 ? '1' : disabledOpacity}}/></Col>
            </Row>
          }
          onClick={() => navTo('/delivery-zone/client-number', { state : { ...state, size: 'M', lockers: lockers }})}
          disabled={normalSizeMLockersAvailable().length !== 0 ? false : true}
        />
      </Row>

      <Row className="m-0">
        <MenuButton
          text={t.messages['app.sizeL']}
          subtext={`${normalSizeLLockersAvailable().length} ${t.messages['app.availableLockers']}`}
          icon={
            <Row>
              <Col xs="4"><Compras height="34" width="34" style={{opacity: normalSizeLLockersAvailable().length !== 0 ? '1' : disabledOpacity}}/></Col>
              <Col xs="4"><Mala height="34" width="34" style={{opacity: normalSizeLLockersAvailable().length !== 0 ? '1' : disabledOpacity}}/></Col>
              <Col xs="4"><Encomendas height="34" width="34" style={{opacity: normalSizeLLockersAvailable().length !== 0 ? '1' : disabledOpacity}}/></Col>
            </Row>
          }
          onClick={() => navTo('/delivery-zone/client-number', { state : { ...state, size: 'L', lockers: lockers }})}
          disabled={normalSizeLLockersAvailable().length !== 0 ? false : true}
        />
      </Row>
    </TransitionView>
  )
}