import React from 'react';
import { colors } from 'config/colors';

const styles = {
  container: {
    backgroundColor: colors.yellow,
    borderRadius: '6px',
    fontWeight: '600',
    fontSize: '3.2vw',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
};

export const PrimaryButtonIcon = ({ text, onClick, icon }) => {
  return(
    <div style={styles.container} className="p-4 m-0" onClick={onClick}>
      <span>
        {text}
      </span>
      {icon}
    </div>
  );
};