import React from 'react';
import { Portal }  from 'helpers/Portal';
import { colors } from 'config/colors';
import { Row, Col, Card } from 'reactstrap';
import { ReactComponent as PortugalFlag } from 'assets/images/icons/Portugal.svg';
import { ReactComponent as SpainFlag } from 'assets/images/icons/Spain.svg';
import { ReactComponent as UkFlag } from 'assets/images/icons/UK.svg';
import { LanguageModalButton } from 'components/common/LanguageModalButton';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { PrimaryButton } from 'components/common/PrimaryButton';

const styles = {
  container: {
    backgroundColor: `${colors.bgColor}`,
    borderRadius: '3vw',
    border: 'none'
  },
  text: {
    fontSize: '3.5vw',
    letterSpacing: '0.25vw',
    fontWeight: '600'
  }
}

export const LanguageModal = ({ isOpen, setIsOpen }) => {
  const t = useIntl();
  const dispatch = useDispatch();

  const changeLanguage = (lang) => {
    dispatch({type: 'CHANGE_LANGUAGE', payload: lang})
    setIsOpen(false);
  }

  return(
    <Portal isOpen={isOpen}>
      <Card style={styles.container} className="h-100 justify-content-between p-4 py-5">
        <Row className="d-flex justify-content-center align-items-between">
          <Col xs="12" className="d-flex align-items-center justify-content-center px-4">
            <span style={styles.text}>
              <FormattedMessage id="app.choose-language" />
            </span>
          </Col>
        </Row>
        <Row className="m-0">
            <Col xs="12">
              <LanguageModalButton icon={<PortugalFlag />} text={t.messages['app.language-portuguese']} onClick={() => changeLanguage('pt-PT')}/>
              <LanguageModalButton icon={<SpainFlag />} text={t.messages['app.language-spanish']} onClick={() => changeLanguage('es-ES')}/>
              <LanguageModalButton icon={<UkFlag />} text={t.messages['app.language-english']} onClick={() => changeLanguage('en-US')}/>
            </Col>
          </Row>
        <Row className="text-center m-0">
          <Col xs="12">
            <PrimaryButton
              text={t.messages['app.goBack']}
              onClick={() => setIsOpen(false)}
              color="white"
              outline={true}
              border="lightGrey"
            />
          </Col>
        </Row>
      </Card>
    </Portal>
  );
};
