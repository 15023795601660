import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { LanguageModal } from 'components/common/LanguageModal';
import { SquareIconButton } from 'components/common/SquareIconButton';
import { useIntl } from 'react-intl';
import { ReactComponent as PortugalFlag } from 'assets/images/icons/Portugal.svg';
import { ReactComponent as SpainFlag } from 'assets/images/icons/Spain.svg';
import { ReactComponent as UkFlag } from 'assets/images/icons/UK.svg';
import { ReactComponent as MarShopping } from 'assets/images/icons/mar-shopping.svg';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getTopBarLabel } from 'helpers/utils';
import { useNavigate } from 'react-router';

const styles = {
  text: {
    fontSize: '4vw', 
    fontWeight: '600'
  }
};

export const TopNav = () => {
  const locale = useSelector(state => state.language);
  const t = useIntl();

  let navigate = useNavigate();

  const pathname = useLocation().pathname;

  const [isOpen, setIsOpen] = useState(false);

  const getLangIcon = () => {
    if(locale === 'pt-PT') return <PortugalFlag />
    if(locale === 'es-ES') return <SpainFlag />
    if(locale === 'en-US') return <UkFlag />
  };

  const goHome = () => {
    setTimeout(() => {
      navigate(process.env.REACT_APP_BASE_URL);
    }, 1);
  };

  return(
    <Row className="p-4 align-items-center">
      <Col xs="2" onClick={goHome}>
        <SquareIconButton icon={<MarShopping height="130" />} />
      </Col>
      <Col xs="8">
        <span style={styles.text}>
          <FormattedMessage id={getTopBarLabel(pathname)} defaultMessage="os" />
        </span>
      </Col>
      <Col xs="2">
        <SquareIconButton text={t.messages['app.language']} icon={getLangIcon()} onClick={() => setIsOpen(true)}/>
      </Col>
      <LanguageModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </Row>
  );
};