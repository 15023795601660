import * as React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { NotFoundView } from 'views/not-found';
import { YellowZoneHome } from 'views/yellow-zone/home';
import { YellowZoneLokkerType } from 'views/yellow-zone/lokker-type';
import { YellowZoneLokkerSize } from 'views/yellow-zone/lokker-size';
import { YellowZoneMyLokkers } from 'views/yellow-zone/my-lokkers';
import { YellowZoneCreatePin } from 'views/yellow-zone/create-pin';
import { YellowZoneLockerAssigned } from 'views/yellow-zone/locker-assigned';
import { AppLayout } from 'layouts/AppLayout';
import { YellowZoneAccessibleLokkers } from 'views/yellow-zone/accessible-lokkers';
import { YellowZoneReleaseLokker } from 'views/yellow-zone/release-lokker';
import { YellowZoneLokkerReleased } from 'views/yellow-zone/lokker-released';
import { YellowZoneLokkerOpened } from 'views/yellow-zone/lokker-opened';
import { YellowZoneAccessMyLokkers } from 'views/yellow-zone/access-my-lokkers';
import { YellowZonePickNCollect } from 'views/yellow-zone/pick-and-collect';
import { YellowZoneDepositSize } from 'views/yellow-zone/deposit-size';
import { YellowZoneDepositCreatePin } from 'views/yellow-zone/deposit-create-pin';
import { YellowZoneDepositConfirm } from 'views/yellow-zone/deposit-confirm';
import { YellowZoneDepositLokkerOpened } from 'views/yellow-zone/deposit-lokker-opened';
import { YellowZoneCollectAccessPin } from 'views/yellow-zone/collect-access-pin';
import { YellowZoneCollectObject } from 'views/yellow-zone/collect-object';
import { DeliveryZoneHome } from 'views/delivery-zone/home';
import { DeliveryZoneColaboratorAccess } from 'views/delivery-zone/colaborator-access';
import { DeliveryZoneCollectObject } from 'views/delivery-zone/collect-object';
import { DeliveryZoneLokkerSize } from 'views/delivery-zone/lokker-size';
import { DeliveryZoneClientNumber } from 'views/delivery-zone/client-number';
import { DeliveryZoneDeliveryConfirm } from 'views/delivery-zone/delivery-confirm';
import { DeliveryZoneDepositLokkerOpened } from 'views/delivery-zone/deposit-lokker-opened';

export const AppRouter = () => {
 
  return(
    <BrowserRouter>
      <AppLayout>
        <Routes>
          <Route index element={<Navigate to="yellow-zone" />} />
          <Route path="yellow-zone">
            <Route index element={<YellowZoneHome />}/>
            <Route path="lokker-type" element={<YellowZoneLokkerType />} />
            <Route path="lokker-size" element={<YellowZoneLokkerSize />} />
            <Route path="access-my-lokkers" element={<YellowZoneAccessMyLokkers />} />
            <Route path="my-lokkers" element={<YellowZoneMyLokkers />} />
            <Route path="create-pin" element={<YellowZoneCreatePin />} />
            <Route path="lokker-assigned" element={<YellowZoneLockerAssigned />} />
            <Route path="accessible-lokker" element={<YellowZoneAccessibleLokkers />} />
            <Route path="release-lokker" element={<YellowZoneReleaseLokker />} />
            <Route path="lokker-released" element={<YellowZoneLokkerReleased />} />
            <Route path="lokker-opened" element={<YellowZoneLokkerOpened />} />
            <Route path="pick-and-collect" element={<YellowZonePickNCollect />} />
            <Route path="deposit-size" element={<YellowZoneDepositSize />} />
            <Route path="deposit-create-pin" element={<YellowZoneDepositCreatePin />} />
            <Route path="deposit-confirm" element={<YellowZoneDepositConfirm />} />
            <Route path="deposit-lokker-opened" element={<YellowZoneDepositLokkerOpened />} />
            <Route path="collect-access-pin" element={<YellowZoneCollectAccessPin />} />
            <Route path="collect-object" element={<YellowZoneCollectObject />} />
          </Route>
          <Route path="delivery-zone">
            <Route index element={<DeliveryZoneHome />}/>
            <Route path="colaborator-access" element={<DeliveryZoneColaboratorAccess />} />
            <Route path="collect-object" element={<DeliveryZoneCollectObject />} />
            <Route path="lokker-size" element={<DeliveryZoneLokkerSize />} />
            <Route path="client-number" element={<DeliveryZoneClientNumber />} />
            <Route path="delivery-confirm" element={<DeliveryZoneDeliveryConfirm />} />
            <Route path="deposit-lokker-opened" element={<DeliveryZoneDepositLokkerOpened />} />
          </Route>
          {/* Not Found Route */}
          <Route path="*" element={<NotFoundView />} />
          {/* Not Found Route */}
        </Routes>
      </AppLayout>
    </BrowserRouter>
  );
};