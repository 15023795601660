const lokkersReducer = (state = { lokkersList : [] }, action) => {
  switch(action.type){
    case 'GET_LOKKERS_SUCCESS':
      return {
        ...state,
        lokkersList: action.payload
      }
    default:
       return state;
  }
};

export default lokkersReducer;