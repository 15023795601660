import React from 'react';
import { Row, Col } from 'reactstrap';
import { useNavigate, useLocation } from 'react-router';
import { TransitionView } from 'components/common/transition-view';
import { useRouterTransition } from 'hooks/useRouterTransition';
import { useIntl } from 'react-intl';
import { MenuButton } from 'components/common/MenuButton';
import { ReactComponent as Carteira } from 'assets/images/icons/Carteira.svg';
import { ReactComponent as Mochila } from 'assets/images/icons/Mochila.svg';
import { ReactComponent as Portatil } from 'assets/images/icons/Portatil.svg';
import { ReactComponent as Capacete } from 'assets/images/icons/Capacete.svg';
import { ReactComponent as Saco } from 'assets/images/icons/Saco.svg';
import { ReactComponent as Malinha } from 'assets/images/icons/Malinha.svg';
import { ReactComponent as Compras } from 'assets/images/icons/Compras.svg';
import { ReactComponent as Mala } from 'assets/images/icons/Mala.svg';
import { ReactComponent as Encomendas } from 'assets/images/icons/Encomendas.svg';
import { disabledOpacity } from 'config/appConfig';
import { SecondaryNav } from 'components/common/SecondaryNav';


export const YellowZoneLokkerSize = () => {
  const t = useIntl();
  let navigate = useNavigate();
  const { state } = useLocation();
  let { transitionType, handleTransition } = useRouterTransition(navigate);
  
  const navTo = (url, state) =>{
    handleTransition(url, state)
  };

  const normalSizeSLockersAvailable = () => {
    return state.lockers.filter(function (locker) {
      return (
        locker.enabled === 1 && 
        locker.status === 0 && 
        locker.locker_metra_status === 2 &&
        locker.lockerType === state.type &&
        locker.size === 'S' &&
        ( state.accessibility === 0 ? (locker.accessibility === 0 || locker.accessibility === 1) : locker.accessibility === 1 )
      )
    });
  };

  const normalSizeMLockersAvailable = () => {
    return state.lockers.filter(function (locker) {
      return (
        locker.enabled === 1 && 
        locker.status === 0 && 
        locker.locker_metra_status === 2 && 
        locker.lockerType === state.type && 
        locker.size === 'M' && 
        ( state.accessibility === 0 ? (locker.accessibility === 0 || locker.accessibility === 1) : locker.accessibility === 1 )
      )
    });
  };

  const normalSizeLLockersAvailable = () => {
    return state.lockers.filter(function (locker) {
      return (
        locker.enabled === 1 && 
        locker.status === 0 && 
        locker.locker_metra_status === 2 && 
        locker.lockerType === state.type && 
        locker.size === 'L' && 
        ( state.accessibility === 0 ? (locker.accessibility === 0 || locker.accessibility === 1) : locker.accessibility === 1 )
      )
    });
  };

  return(
    <TransitionView animation="fade-down" transitionType={transitionType} className="position-relative">
      <SecondaryNav secNavText="app.pickLockerSize" navToURL="/yellow-zone/lokker-type" />
      <Row className="m-0">
        <MenuButton
          text={t.messages['app.sizeS']}
          subtext={`${normalSizeSLockersAvailable().length} ${t.messages['app.availableLockers']}`}
          icon={
            <Row>
              <Col xs="4"><Carteira height="26" width="26" style={{opacity: normalSizeSLockersAvailable().length !== 0 ? '1' : disabledOpacity}} /></Col>
              <Col xs="4"><Malinha height="26" width="26" style={{opacity: normalSizeSLockersAvailable().length !== 0 ? '1' : disabledOpacity}} /></Col>
              <Col xs="4"><Portatil height="26" width="26" style={{opacity: normalSizeSLockersAvailable().length !== 0 ? '1' : disabledOpacity}} /></Col>
            </Row>
          }
          onClick={() => navTo('/yellow-zone/create-pin', { state : { ...state, size: 'S'}})}
          disabled={normalSizeSLockersAvailable().length !== 0 ? false : true}
        />
      </Row>

      <Row className="m-0">
        <MenuButton
          text={t.messages['app.sizeM']}
          subtext={`${normalSizeMLockersAvailable().length} ${t.messages['app.availableLockers']}`}
          icon={
            <Row>
              <Col xs="4"><Capacete height="30" width="30" style={{opacity: normalSizeMLockersAvailable().length !== 0 ? '1' : disabledOpacity}}/></Col>
              <Col xs="4"><Saco height="30" width="30" style={{opacity: normalSizeMLockersAvailable().length !== 0 ? '1' : disabledOpacity}}/></Col>
              <Col xs="4"><Mochila height="30" width="30" style={{opacity: normalSizeMLockersAvailable().length !== 0 ? '1' : disabledOpacity}}/></Col>
            </Row>
          }
          onClick={() => navTo('/yellow-zone/create-pin', { state : { ...state, size: 'M'}})}
          disabled={normalSizeMLockersAvailable().length !== 0 ? false : true}
        />
      </Row>

      <Row className="m-0">
        <MenuButton
          text={t.messages['app.sizeL']}
          subtext={`${normalSizeLLockersAvailable().length} ${t.messages['app.availableLockers']}`}
          icon={
            <Row>
              <Col xs="4"><Compras height="34" width="34" style={{opacity: normalSizeLLockersAvailable().length !== 0 ? '1' : disabledOpacity}}/></Col>
              <Col xs="4"><Mala height="34" width="34" style={{opacity: normalSizeLLockersAvailable().length !== 0 ? '1' : disabledOpacity}}/></Col>
              <Col xs="4"><Encomendas height="34" width="34" style={{opacity: normalSizeLLockersAvailable().length !== 0 ? '1' : disabledOpacity}}/></Col>
            </Row>
          }
          onClick={() => navTo('/yellow-zone/create-pin', { state : { ...state, size: 'L'}})}
          disabled={normalSizeLLockersAvailable().length !== 0 ? false : true}
        />
      </Row>
    </TransitionView>
  )
}