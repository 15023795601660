import { useState } from 'react';
import { routerTransitionDelay } from 'config/appConfig';

export const useRouterTransition = (navigate) => {
  const [transitionType, setTransitionType] = useState('in');

  const handleTransition = (path, state) => {
    setTransitionType('out');
    setTimeout(() => {
      navigate(path, state);
    }, routerTransitionDelay * 1000);
  }

  return { transitionType, handleTransition };
};
