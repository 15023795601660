import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from 'reactstrap';
import { colors } from 'config/colors';
import { ReactComponent as WarningIcon } from 'assets/images/icons/WarningIcon.svg';

const styles = {
  container: {
    backgroundColor: colors.white,
    border: `1px solid ${colors.lightGrey}`,
    borderRadius: '6px',
    paddingTop: '3.5vw'
  },
  headerText: {
    fontSize: '3.5vw',
    fontWeight: '600'
  }, 
  paragraphsText: {
    fontSize: '3.5vw',
    fontWeight: '300'
  },
  footerText: {
    fontSize: '3vw'
  }
}

export const MessageBox = ({ lokkerId }) => {
  const zone = 'Zona Amarela';

  return (
    <div style={styles.container}>
      <Row className="justify-content-center py-2">
        <WarningIcon />
      </Row>

      <Row className="justify-content-center py-4">
        <Col xs="10">
          <span style={styles.headerText}>
            <FormattedMessage id="app.makeSure" />
          </span>
        </Col>
      </Row>

      <Row className="justify-content-center py-4">
        <Col xs="10">
          <span style={styles.paragraphsText}>
            <FormattedMessage id="app.youAreIn" values={{ lokkerId: lokkerId, zone: zone }} />
          </span>
        </Col>
      </Row>

      <Row className="justify-content-center py-4">
        <Col xs="10">
          <span style={styles.paragraphsText}>
            <FormattedMessage id="app.dontLeave" />
          </span>
        </Col>
      </Row>

      <Row className="justify-content-center py-4">
        <Col xs="10">
          <span style={styles.footerText}>
            <FormattedMessage id="app.weAreNotResponsible" />
          </span>
        </Col>
      </Row>
      
    </div>
  );
};