import React from 'react';
import { colors } from 'config/colors';

const styles = {
  button: {
    height: '150px',
    width: '150px',
    backgroundColor: colors.cardBgColor,
    borderRadius: '20px',
    border: `1px solid ${colors.lightGrey}`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 20px',
  },
  text: {
    fontSize: '2.5vw'
  }
}

export const SquareIconButton = ({ icon, text, onClick }) => {
  return(
    <button style={styles.button} onClick={onClick}>
      <span>{icon}</span>
     {text !== undefined && 
      <span style={styles.text} className="mt-2">
        {text}
      </span>}
    </button>
  )
}