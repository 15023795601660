import React, { useState, useEffect } from 'react';
import { Row } from 'reactstrap';
import { useNavigate, useLocation } from 'react-router';
import { TransitionView } from 'components/common/transition-view';
import { useRouterTransition } from 'hooks/useRouterTransition';
import { Numpad } from 'components/common/numpad/Numpad';
import { Input } from 'components/common/numpad/Input';
import { useIntl } from 'react-intl';
import { PrimaryButton } from 'components/common/PrimaryButton';

export const YellowZoneDepositCreatePin = () => {
  const t = useIntl();
  let navigate = useNavigate();
  const { state } = useLocation();

  let { transitionType, handleTransition } = useRouterTransition(navigate);

  const [selectedInput, setSelectedInput] = useState('phoneNumber');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumber2, setPhoneNumber2] = useState('');

  const [phoneAnimation, setPhoneAnimation] = useState('');

  const phoneNumberSize = 9;
  const phoneNumber2Size = 9;
  const invalidInputAnimationDuration = 0.7;

  const handleChangePhoneNumber = (val) => {
    // delete last char from phoneNumber
    if(val === '#') setPhoneNumber(phoneNumber.slice(0, -1));
    // add char to phoneNumber
    // if last char also go to next input
    else if(phoneNumber.length === phoneNumberSize - 1){
      setPhoneNumber(phoneNumber + val);
      setSelectedInput('phoneNumber2');
    } 
    else if(phoneNumber.length < phoneNumberSize) setPhoneNumber(phoneNumber + val);
    else if(phoneNumber.length === phoneNumberSize) setSelectedInput('phoneNumber2');
  };

  const handleChangePhoneNumber2 = (val) => {
    // delete last char from pin
    if(val === '#'){
      if(phoneNumber2.length === 0) setSelectedInput('phoneNumber');
      else setPhoneNumber2(phoneNumber2.slice(0, -1));
    }
    // add char to pin
    // if last char also go to next input
    else if(phoneNumber2.length === phoneNumber2Size - 1){
      setPhoneNumber2(phoneNumber2 + val);
    }
    else if(phoneNumber2.length < phoneNumber2Size) setPhoneNumber2(phoneNumber2 + val);
  };

  const navTo = (url, state) =>{
    handleTransition(url, state)
  };

  const handleNumpadInput = (val) => {
    // when user presses asterisk clear everything
    if(val === '*'){
      setPhoneNumber('');
      setPhoneNumber2('');
      setSelectedInput('phoneNumber');
    }
    else{
      // handle specific input
      switch (selectedInput) {
        case 'phoneNumber':
          handleChangePhoneNumber(val)
          break;
        case 'phoneNumber2':
          handleChangePhoneNumber2(val)
          break;
        default:
          break;
      }
    }
  };

  const isPhoneNumberValid = () => {
    const pattern = /\d{9}/;
    if(phoneNumber.match(pattern)){
      // is valid
      return true;
    }
  };

  const isPhoneNumber2Valid = () => {
    const pattern = /\d{9}/;
    if(phoneNumber2.match(pattern) && phoneNumber !== phoneNumber2){
      // is valid
      return true;
    }
  };

  const validateInput = () => {
    if(isPhoneNumberValid() && isPhoneNumber2Valid()){
      // input is valid
      return true;
    } else{
      // input is invalid
      return false;
    }
  };

  const handleSubmit = () => {
    navTo('/yellow-zone/deposit-confirm', { state: { number: phoneNumber, receiverNumber: phoneNumber2, lockerSize: state?.lockerSize }});
  };

  const goBack = () => {
    navTo('/yellow-zone/deposit-size');
  };

  useEffect(() => {
    if(phoneNumber2.length > 1 ) {
      if(phoneNumber === phoneNumber2){
        setPhoneAnimation('shake');
        setTimeout(() => {
          setPhoneNumber2('');
          setSelectedInput('phoneNumber2');
          setPhoneAnimation('');
        }, invalidInputAnimationDuration * 1000);
      }
    }
  }, [phoneNumber2]);

  return(
    <TransitionView animation="fade-down" transitionType={transitionType} className="justify-content-between">
      <Row className="m-0">
        <Input
          label={t.messages['app.insertYourPhoneNumber']}
          size={phoneNumberSize}
          type="number"
          value={phoneNumber}
          isFocused={selectedInput === 'phoneNumber'}
        />
      </Row>
      <Row className="m-0">
        <Input
          label={t.messages['app.insertReceiverPhoneNumber']}
          size={phoneNumber2Size}
          type="number"
          value={phoneNumber2}
          isFocused={selectedInput === 'phoneNumber2'}
          style={{animation: `${phoneAnimation} ${invalidInputAnimationDuration}s ease-in-out`}}
        />
      </Row>
      <Row className="m-0">
        <Numpad handleChange={handleNumpadInput} />
      </Row>
      <Row className="m-0">
        <PrimaryButton
          text={t.messages['app.confirm']}
          onClick={handleSubmit}
          isEnabled={validateInput()}
          color="yellow"
        />
        <PrimaryButton
          text={t.messages['app.cancel']}
          onClick={goBack}
          color="white"
          outline={true}
          border="lightGrey"
        />
      </Row>
    </TransitionView>
  )
}