import React, { useState } from 'react';
import { Row } from 'reactstrap';
import { useNavigate, useLocation } from 'react-router';
import { TransitionView } from 'components/common/transition-view';
import { useRouterTransition } from 'hooks/useRouterTransition';
import { FormattedMessage, useIntl } from 'react-intl';
import { PrimaryButton } from 'components/common/PrimaryButton';
import { DeliveryConfirmMessageBox } from 'components/common/DeliveryConfirmMessageBox';
import { LoadingModal } from 'components/common/LoadingModal';
import api from 'helpers/api';

const styles = {
  headerText: {
    fontSize: '4vw',
    fontWeight: '600'
  }
};

export const DeliveryZoneDeliveryConfirm = () => {
  const t = useIntl();
  let navigate = useNavigate();
  const { state } = useLocation();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);

  let { transitionType, handleTransition } = useRouterTransition(navigate);

  const navTo = (url) =>{
    handleTransition(url)
  };
  
  const openLokker = () => {
    setIsSubmitting(true);
    setLoading(true);
    api.post(`${process.env.REACT_APP_API_URL}api/mar-shopping/createDeliveryAndOpenLocker`, {
      locationId: process.env.REACT_APP_LOCATION_ID,
      packageId: `${state?.code}_${state.number}_${Date.now()}`,
      recipientPhone: state?.number,
      recipientName: 'MARSHOPPING', 
      size: state?.size ,
    })
      .then(function (response) {
        console.log(response);
        if (response?.data?.ResultCode === 1) {
          console.log(response?.data?.Data?.assignedLocker);
          handleTransition('/delivery-zone/deposit-lokker-opened', { state: { locker: response?.data?.Data?.assignedLocker } })
        } else if (response?.data?.Description === 'Invalid PIN') {
          console.log(response?.data?.Description);
        } else {
          console.log(response?.data);
          console.log('Unknow Error');
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })    
      .finally(() => {
        setIsSubmitting(false);
        setLoading(false);
      })
  };

  return(
    <TransitionView animation="fade-down" transitionType={transitionType} className="justify-content-start position-relative">
      <LoadingModal isLoading={loading} />
      <Row className="py-5 m-0">
        <span style={styles.headerText}>
          <FormattedMessage id="app.depositConfirm" />
        </span>
      </Row>
      <Row className="mb-3 m-0">
        <DeliveryConfirmMessageBox clientPhoneNumber={state.number} lokkerSize={state.size}/>
      </Row>
      <Row className="m-0">
        <PrimaryButton text={t.messages['app.confirm']} color="red" txtColor="light" onClick={isSubmitting ? () => null : () => openLokker()} isEnabled={!isSubmitting}/>
      </Row>
      <Row className="m-0">
        <PrimaryButton text={t.messages['app.cancel']} color="white" border="lightGrey" onClick={() => navTo('/delivery-zone')}/>
      </Row>
    </TransitionView>
  )
}