import React, { useState, useEffect } from 'react';
import { CustomQrCode } from 'components/common/CustomQrCode';
import { Row, Col } from 'reactstrap';
import { useNavigate } from 'react-router';
import { TransitionView } from 'components/common/transition-view';
import { useRouterTransition } from 'hooks/useRouterTransition';
import { useIntl } from 'react-intl';
import { Numpad } from 'components/common/numpad/Numpad';
import { Input } from 'components/common/numpad/Input';
import { PrimaryButton } from 'components/common/PrimaryButton';
import api from 'helpers/api';
import { LoadingModal } from 'components/common/LoadingModal';

const styles= {
  row: {
    height: '30%',
    margin: '0px'
  },
  middleRow: {
    height: '20%',
    fontSize: '2.5vw',
    margin: '0px'
  },
  rowBottom: {
    height: '60%',
    margin: '0px'
  },
  boldHintText: {
    fontWeight: '600',
    marginBottom: '-10vw'
  }
};

export const DeliveryZoneHome = () => {
  const t = useIntl();
  const navigate = useNavigate();
  let { transitionType, handleTransition } = useRouterTransition(navigate);

  const [selectedInput, setSelectedInput] = useState('pin');
  const [pin, setPin] = useState('');
  const [ isSubmitting, setIsSubmitting ] = useState(false);
  const [ loading, setLoading ] = useState(false);

  const [pinAnimation, setPinAnimation] = useState('');

  const invalidInputAnimationDuration = 0.7;

  const pinSize = 6;

  const [ counter, setCounter] = useState(0);
  var timer;


  const navTo = (url, state) =>{
    handleTransition(url, state)
  };

  const updateCounter = () => {
    if(counter < 4) {
      setCounter(counter + 1);
    }
  };

  const handleChangePin = (val) => {
    // delete last char from pin
    if(val === '#'){
      if(pin.length === 0) setSelectedInput('pin');
      else setPin(pin.slice(0, -1));
    }
    // add char to pin
    // if last char also go to next input
    else if(pin.length === pinSize - 1){
      setPin(pin + val);
    }
    else if(pin.length < pinSize) setPin(pin + val);
  };

  const handleNumpadInput = (val) => {
    // when user presses asterisk clear everything
    if(val === '*'){
      setPin('');
      setSelectedInput('pin');
    }
    else{
      // handle specific input
      switch (selectedInput) {
        case 'pin':
          handleChangePin(val)
          break;
        default:
          break;
      }
    }
  };

  const isPinValid = () => {
    const pattern = /\d{6}/;
    if(pin.match(pattern)){
      // is valid
      return true;
    }
  };

  const validateInput = () => {
    if(isPinValid()){
      // input is valid
      return true;
    } else{
      // input is invalid
      return false;
    }
  };

  const handleSubmit = () => {
    setIsSubmitting(true);
    setLoading(true);
    api.get(`${process.env.REACT_APP_API_URL}api/mar-shopping/deliveryDetailsWithPin?pin=${pin}`)
    .then(function (response) {
      console.log(response);
      if (response?.data?.ResultCode === 1) {
        console.log(response?.data)
        navTo('/delivery-zone/collect-object', { state: { locker: response?.data?.Data?.assignedLocker, pin: pin } });
      } else {
        console.log(response?.data);
        setPinAnimation('shake');
        setTimeout(() => {
          setPin('');
          setPinAnimation('');
        }, invalidInputAnimationDuration * 1000);
      }
    })
    .catch(function (err) {
      console.log(err);
      setPinAnimation('shake');
      setTimeout(() => {
        setPin('');
        setPinAnimation('');
      }, invalidInputAnimationDuration * 1000);
    })
    .finally(() => {
      setIsSubmitting(false);
      setLoading(false);
    });

  };

  useEffect(() => {
    if(counter === 4){
      navTo('/delivery-zone/colaborator-access')
    }

    timer = setTimeout(() => setCounter(0), 2 * 1000);

    return () => {
      clearTimeout(timer);
    };

  }, [counter])

  return(
    <TransitionView animation="fade-down" transitionType={transitionType} className="position-relative">
      <LoadingModal isLoading={loading} />
      <Row style={styles.row} className="align-items-center">
        <Col className="d-flex justify-content-center w-100">
          <div id="click" onClick={() => updateCounter()}>
            <CustomQrCode value="http://95.216.196.165:3000/delivery-zone"/>
          </div>
        </Col>
      </Row>
      <Row style={styles.middleRow} className="justify-content-center align-items-start">
        <span style={styles.boldHintText}>{t.messages['app.qrCodeHint']}</span>
        <span>{t.messages['app.qrCodeHintDelivery']}</span>
        <span style={styles.boldHintText}>{t.messages['app.or']}</span>
      </Row>
      <Row style={styles.rowBottom} className="align-items-center">
        <Input
            label={t.messages['app.insertSMSCode']}
            size={pinSize}
            type="number"
            value={pin}
            isFocused={selectedInput === 'pin'}
            style={{animation: `${pinAnimation} ${invalidInputAnimationDuration}s ease-in-out`}}
        />
        <Numpad handleChange={handleNumpadInput} />
        <PrimaryButton
          text={t.messages['app.confirm']}
          onClick={isSubmitting ? () => null : handleSubmit}
          isEnabled={validateInput() && !isSubmitting}
          color="red"
          txtColor="light"
        />
      </Row>
    </TransitionView>
  );
};