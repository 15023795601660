import React from 'react';
import { AppRouter } from './router';

import './App.css';
import 'assets/css/animations.css';
import 'bootstrap/dist/css/bootstrap.min.css';


function App() {
  return (
    <div className="App">
      <AppRouter />
    </div>
  );
}

export default App;
