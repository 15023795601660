import React from 'react';
import { Row, Col } from 'reactstrap';
import { colors } from 'config/colors';

const styles = {
  container: {
    borderRadius: '6px',
    border: `1px solid ${colors.lightGrey}`,
    backgroundColor: "#fff",
    margin: '2vw 0'
  },
  text: {
    fontSize: '2.5vw',
    fontWeight: '600',
    letterSpacing: '0.25vw'
  },
}


export const LanguageModalButton = ({ icon, text, onClick }) => {
  return(
    <Row style={styles.container} className="px-4 py-4" onClick={onClick}>
      <Col xs="4" className="d-flex justify-content-centeralign-items-center">
        <span>
          {icon}
        </span>
      </Col>
      <Col xs="4" className="d-flex justify-content-center align-items-center">
        <span style={styles.text}>
          {text}
        </span>
      </Col>
      <Col xs="4"></Col>
    </Row>
  )
}