import React from 'react';
import { Row, Col } from 'reactstrap';
import { useNavigate } from 'react-router';
import { useIntl } from 'react-intl';
import { useRouterTransition } from 'hooks/useRouterTransition';

const styles = {
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '0px',
    marginTop: '40px',
    marginBottom: '40px',
  },
  text: {
    fontSize: '3.2vw',
    fontWeight: '600',
    textAlign: 'left'
  },
  goBackText: {
    textDecoration: 'underline',
    textAlign: 'right'
  },
  textLeft: {
    textAlign: 'left'
  },
  textRight: {
    textAlign: 'right'
  }
}

export const SecondaryNav = ({ secNavText, navToURL }) => {
  const t = useIntl();
  let navigate = useNavigate();
  let { handleTransition } = useRouterTransition(navigate);

  const navTo = (url, state) =>{
    handleTransition(url, state)
  };

  return (
    <Row style={styles.row}>
      <Col>
        <p style={{...styles.text, ...styles.textLeft}} >
          {t.messages[`${secNavText}`]}
        </p>
      </Col>
      <Col>
        <p style={{...styles.goBackText, ...styles.text, ...styles.textRight}} onClick={() => navTo(`${navToURL}`)}>
          {t.messages['app.goBack']}
        </p>
      </Col>
    </Row>
  );
};