import React from 'react';
import { useNavigate, useLocation } from 'react-router';
import { TransitionView } from 'components/common/transition-view';
import { useRouterTransition } from 'hooks/useRouterTransition';
import { LockerInfo } from 'components/common/LockerInfo';
import { useIntl } from 'react-intl';
import { Row } from 'reactstrap';
import { PrimaryButton } from 'components/common/PrimaryButton';
import { FormattedMessage } from 'react-intl';
import { colors} from 'config/colors';

export const YellowZoneDepositLokkerOpened = () => {
  const t = useIntl();
  let navigate = useNavigate();
  const { state } = useLocation();

  let { transitionType, handleTransition} = useRouterTransition(navigate);
  return(
    <TransitionView animation="fade-down" transitionType={transitionType} className="justify-content-center">
      <Row className="m-0">
        <LockerInfo text={<FormattedMessage id='app.depositObjectOn' values={{ lokkerNum: state?.locker?.locker_metra_id }}/>} number={state?.locker?.locker_metra_id} size={state?.locker?.size} type={0} />
        <PrimaryButton text={t.messages['app.goBackToMenu']} color="white" border={colors.lightGrey} onClick={() => handleTransition('/yellow-zone')} />
      </Row>
    </TransitionView>
  );
}