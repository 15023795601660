import React from 'react';
import { Row } from 'reactstrap';
import QRCode from "react-qr-code";

import { colors } from 'config/colors';

export const CustomQrCode = ({ value }) =>{
  return(
    <div style={styles.container} className="p-4">
      <QRCode size={256} fgColor={colors.fontPrimary} value={value} />
    </div>
  )
}

const styles = {
  container: {
    borderRadius: '6px',
    border: `1px solid ${colors.lightGrey}`,
    backgroundColor: '#fff',
    width: 'fit-content'
  }
};