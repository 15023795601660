import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import { useNavigate, useLocation } from 'react-router';
import { TransitionView } from 'components/common/transition-view';
import { useRouterTransition } from 'hooks/useRouterTransition';
import { useIntl } from 'react-intl';
import { MenuButton } from 'components/common/MenuButton';
import { OpenReleaseButtons } from 'components/common/OpenReleaseButtons';
import { ReactComponent as Lokker } from 'assets/images/icons/Lokker.svg';
import { ReactComponent as Frio } from 'assets/images/icons/Frio.svg';
import { LoadingModal } from 'components/common/LoadingModal';
import api from 'helpers/api';
import moment from 'moment';
import 'moment/locale/pt';
import 'moment/locale/en-gb';
import { SecondaryNav } from 'components/common/SecondaryNav';


const styles = {
  sizeText: {
    fontSize: '3.5vw', 
    fontWeight: '600',
    marginTop: '1vw'
  },

  overflowRow: {
    overflowY: 'scroll',
    margin: '0px'
  }
};

export const YellowZoneMyLokkers = () => {
  const t = useIntl();
  let navigate = useNavigate();
  let { transitionType, handleTransition } = useRouterTransition(navigate);
  const [ loading, setLoading ] = useState(false);
  const [ isSubmitting, setIsSubmitting ] = useState(false);
  
  const { state } = useLocation();

  const navTo = (path) => {
    handleTransition(path)
  };

  const releaseLokker = (lokker) => {
    handleTransition('/yellow-zone/release-lokker', { state: { locker: lokker, lockers: state.lockers, phoneNumber: state.phoneNumber, pin: state.pin  } })
  };

  const openLokker = (lokker) => {
    setIsSubmitting(true);
    setLoading(true);
    api.get(`${process.env.REACT_APP_API_URL}api/mar-shopping/openLockerWithPin?locationId=${process.env.REACT_APP_LOCATION_ID}&number=${state.phoneNumber}&pin=${state.pin}&lockerId=${lokker.id}`)
    .then(function (response) {
      console.log(response);
      if (response?.data?.ResultCode === 1) {
        console.log(response?.data?.ResultCode)
        handleTransition('/yellow-zone/lokker-opened', { state: { locker: lokker } })
      } else {
        console.log(response?.data?.Result);
      }
    })
    .catch(function (err) {
      console.log(err);
    })
    .finally(() => {
      setLoading(false)
      setIsSubmitting(false);
    });
  };

  
  return(
    <TransitionView animation="fade-down" transitionType={transitionType} className="justify-content-start position-relative">
      <LoadingModal isLoading={loading} />
      <SecondaryNav secNavtext={state.lockers.length !== 0 ? 'app.openOrReleaseLokker' : 'app.noLockerAssociated'}  navToURL="/yellow-zone"/>
      
      <Row style={styles.overflowRow}>
      {
        Object.keys(state.lockers).map((key, index) => 
          <Row key={index} className="mb-3 m-0">
            <MenuButton
              text={`${t.messages['app.doorLabel']} ${state.lockers[key].number}`}
              subtext={`${t.messages['app.orderAt']} ${moment(state.lockers[key].statusChangedAt).format('LTS')}`}
              icon={<Row>
                <Col xs="12" className="d-flex flex-column">
                  <Lokker height="50" width="50"/>
                  <span style={styles.sizeText}>
                    {state.lockers[key].size}
                    </span>
                  </Col>
              </Row>
              }
            />
            <OpenReleaseButtons 
              releaseLokker={() => releaseLokker(state.lockers[key])} 
              openLokker={isSubmitting ? () => null : () => openLokker(state.lockers[key])}
              disabled={isSubmitting}
            />
          </Row>
        )
      }
      </Row>

    </TransitionView>
  )
}