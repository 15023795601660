import { 
  zones, 
  routes
} from 'config/appConfig';

export const getBackgroundColor = ( path )  => {
  let color = 'transparent';
  zones.forEach((key) => {
    if(key.url === path ){
      color = key.color;
    }
  });
  return color;
};

export const getTopBarLabel = ( path )  => {
  let label = 'app.header';

  if(path === '/') return label;

  else {
    routes.forEach((key) => {
      if(key.path === path ){
        label = key.label;
      }
    });
    return label;
  }
};
