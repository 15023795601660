import React from 'react';
import { colors } from 'config/colors';
import { LockerIcon } from 'components/common/LockerIcon';
import LokkerIcon from 'assets/images/icons/Lokker.svg';
import FrioIcon from 'assets/images/icons/Frio.svg';

const styles = {
  container: {
    backgroundColor: colors.cardBgColor,
    border: `1px solid ${colors.lightGrey}`,
    borderRadius: '6px'
  },
  text: {
    fontSize: '1.6em',
    fontWeight: '600'
  }
};

export const LockerInfo = ({ text, number, size, type }) => {
  return(
    <div style={styles.container} className="p-4">
      <div className="py-4 my-4"><LockerIcon lockerNumber={number} size={size} icon={type === 0 ? LokkerIcon : FrioIcon }/></div>
      <div className="py-4 my-4"><span style={styles.text}>{text}</span></div>
    </div>
  );
};