export const zones = [
  {
    name: 'Yellow Zone',
    color: '#FFE600',
    url: 'yellow-zone'
  },
  {
    name: 'Blue Zone',
    color: 'blue',
    url: 'blue-zone',
  },
  {
    name: 'Ikea Zone',
    color: 'yellow',
    url: 'ikea-zone',
  },
  {
    name: 'Delivery Zone',
    color: '#ff1100',
    url: 'delivery-zone',
  },
];

export const routes = [
  { 
    path: "/yellow-zone", 
    label: "route.yellow-zone", 
  },
  { 
    path: "/yellow-zone/lokker-type",
    label: "route.request-lokker",
  },
  { 
    path: "/yellow-zone/lokker-size",
    label: "route.request-lokker",
  },
  { 
    path: "/yellow-zone/create-pin",
    label: "route.request-lokker",
  },
  { 
    path: "/yellow-zone/create-pin",
    label: "route.request-lokker",
  },
  { 
    path: "/yellow-zone/accessible-lokker",
    label: "route.request-lokker",
  },
  { 
    path: "/yellow-zone/my-lokkers",
    label: "route.my-lokkers",
  },
  { 
    path: "/yellow-zone/release-lokker",
    label: "route.my-lokkers",
  },
  { 
    path: "/yellow-zone/lokker-released",
    label: "route.my-lokkers",
  },
  { 
    path: "/yellow-zone/lokker-opened",
    label: "route.my-lokkers",
  },
  { 
    path: "/yellow-zone/pick-and-collect",
    label: "route.pickNCollect",
  },
  { 
    path: "/yellow-zone/deposit-size",
    label: "route.depositObject",
  },
  { 
    path: "/yellow-zone/deposit-create-pin",
    label: "route.depositObject",
  },
  { 
    path: "/yellow-zone/deposit-confirm",
    label: "route.depositObject",
  },
  { 
    path: "/yellow-zone/deposit-lokker-opened",
    label: "route.depositObject",
  },
  { 
    path: "/yellow-zone/collect-access-pin",
    label: "route.collectObject",
  },
  { 
    path: "/yellow-zone/collect-object",
    label: "route.collectObject",
  },
];

export const routerTransitionDelay = 1;

export const disabledOpacity = '0.2';
