import React, { useState, useEffect } from 'react';
import { Row } from 'reactstrap';
import { useNavigate, useLocation } from 'react-router';
import { TransitionView } from 'components/common/transition-view';
import { useRouterTransition } from 'hooks/useRouterTransition';
import { Numpad } from 'components/common/numpad/Numpad';
import { Input } from 'components/common/numpad/Input';
import { useIntl } from 'react-intl';
import { PrimaryButton } from 'components/common/PrimaryButton';
import api from 'helpers/api';
import { LoadingModal } from 'components/common/LoadingModal';

export const YellowZoneCreatePin = () => {
  const t = useIntl();
  let navigate = useNavigate();
  let { state } = useLocation();
  let { transitionType, handleTransition } = useRouterTransition(navigate);
  
  const [selectedInput, setSelectedInput] = useState('phoneNumber');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [pin, setPin] = useState('');
  const [confirmPin, setConfirmPin] = useState('');
  const [pinAnimation, setPinAnimation] = useState('');
  const [confirmPinAnimation, setConfirmPinAnimation] = useState('');
  const [phoneAlreadyInUse, setPhoneAlreadyInUse] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const phoneNumberSize = 9;
  const pinSize = 4;
  const invalidInputAnimationDuration = 0.7;

  const handleChangePhoneNumber = (val) => {
    // delete last char from phoneNumber
    if(val === '#') setPhoneNumber(phoneNumber.slice(0, -1));
    // add char to phoneNumber
    // if last char also go to next input
    else if(phoneNumber.length === phoneNumberSize - 1){
      setPhoneNumber(phoneNumber + val);

      setLoading(true);

      api.post(`${process.env.REACT_APP_API_URL}api/mar-shopping/checkMobileNumber`, {
        number: `${phoneNumber}${val}`
      })
        .then(function (response) {
          console.log(response);
          if (response?.data?.ResultCode === 1) {
            console.log(response?.data);
            setPhoneAlreadyInUse(false);
            setSelectedInput('pin');
          } else if (response?.data?.Error === 'L23') {
            console.log(response?.data?.Description);
            setPhoneAlreadyInUse(true);
            setSelectedInput('pin');
          } else {
            console.log(response?.data);
            console.log('Unknow Error');
            setPhoneAlreadyInUse(false);
            setSelectedInput('phoneNumber');
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        })  
    } 
    else if(phoneNumber.length < phoneNumberSize) setPhoneNumber(phoneNumber + val);
    
  };

  const handleChangePin = (val) => {
    // delete last char from pin
    if(val === '#'){
      if(pin.length === 0) setSelectedInput('phoneNumber');
      else setPin(pin.slice(0, -1));
    }
    // add char to pin
    // if last char also go to next input
    else if(pin.length === pinSize - 1){
      setPin(pin + val);
      setSelectedInput('confirmPin');
    }
    else if(pin.length < pinSize) setPin(pin + val);
    else if(pin.length === pinSize) setSelectedInput('confirmPin');
  };

  const handleChangeConfirmPin = (val) => {
    // delete last char from confirmPin
    if(val === '#'){
      if(confirmPin.length === 0) setSelectedInput('pin');
      else setConfirmPin(confirmPin.slice(0, -1));
    }
    // add char to confirmPin
    else if(confirmPin.length < pinSize) setConfirmPin(confirmPin + val);
  };

  const navTo = (url, state) =>{
    handleTransition(url, state)
  };

  const handleNumpadInput = (val) => {
    // when user presses asterisk clear everything
    if(val === '*'){
      setPhoneNumber('');
      setPin('');
      setConfirmPin('');
      setSelectedInput('phoneNumber');
    }
    else{
      // handle specific input
      switch (selectedInput) {
        case 'phoneNumber':
          handleChangePhoneNumber(val)
          break;
        case 'pin':
          handleChangePin(val)
          break;
        case 'confirmPin':
          handleChangeConfirmPin(val)
          break;
        default:
          break;
      }
    }
  };

  const isPhoneNumberValid = () => {
    const pattern = /\d{9}/;
    if(phoneNumber.match(pattern)){
      // is valid
      return true;
    }
  };

  const isPinValid = () => {
    const pattern = /\d{4}/;
    if(pin.match(pattern)){
      // is valid
      return true;
    }
  };

  const isConfirmPinValid = () => {
    const pattern = /\d{4}/;
    if(confirmPin.match(pattern) && confirmPin === pin){
      // is valid
      return true;
    }
  };

  const validateInput = () => {

    if(phoneAlreadyInUse === false){
      if(isPhoneNumberValid() && isPinValid() && isConfirmPinValid() && pin === confirmPin){
        // input is valid
        return true;
      } else{
        // input is invalid
        return false;
      }
    } else {
      if(isPhoneNumberValid() && isPinValid()){
        // input is valid
        return true;
      } else{
        // input is invalid
        return false;
      }
    }
  };

  const handleSubmit = () => {
    const { type, size } = state; 

    setLoading(true);
    setIsSubmitting(true);

    api.post(`${process.env.REACT_APP_API_URL}api/mar-shopping/assignLockerToUser`, {
      locationId: process.env.REACT_APP_LOCATION_ID,
      size: size,
      number: phoneNumber,
      pin: pin, 
      name: phoneNumber,
      type: type,
    })
      .then(function (response) {
        console.log(response);
        if (response?.data?.ResultCode === 1) {
          console.log(response?.data);
          navTo('/yellow-zone/lokker-assigned', { state: { lockers: response?.data.Data, number: phoneNumber, pin: pin }});
        } else if (response?.data?.Description === 'Invalid PIN') {
          console.log(response?.data?.Description);
          setPinAnimation('shake');
          setTimeout(() => {
            setPinAnimation('');
            setPin('');
          }, invalidInputAnimationDuration * 1000);
          setSelectedInput('pin');
        } else {
          console.log(response?.data);
          console.log('Unknow Error');
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })    
      .finally(() => {
        setLoading(false);
        setIsSubmitting(false);
      });
  };

  const goBack = () => {
    navTo('/yellow-zone/lokker-type');
  };

  useEffect(() => {
    if(confirmPin.length === pinSize && !isConfirmPinValid()){
      setConfirmPinAnimation('shake');
      setTimeout(() => {
        setConfirmPinAnimation('');
        setConfirmPin('');
      }, invalidInputAnimationDuration * 1000);
    }
  }, [confirmPin]);

  return(
    <TransitionView animation="fade-down" transitionType={transitionType} className="justify-content-between position-relative">
      <LoadingModal isLoading={loading} />
      <Row className="m-0">
        <Input
          label={t.messages['app.phoneNumber']}
          size={phoneNumberSize}
          type="number"
          value={phoneNumber}
          isFocused={selectedInput === 'phoneNumber'}
        />
      </Row>
      <Row className="m-0">
        <Input
          label={phoneAlreadyInUse === false ? t.messages['app.createPin'] : t.messages['app.insertYourPin'] }
          size={pinSize}
          type="password"
          value={pin}
          isFocused={selectedInput === 'pin'}
          style={{animation: `${pinAnimation} ${invalidInputAnimationDuration}s ease-in-out`}}
        />
      </Row>
      <Row className="m-0">
        {phoneAlreadyInUse === false &&
          <Input
            label={t.messages['app.confirmPin']}
            size={pinSize}
            type="password"
            value={confirmPin}
            isFocused={selectedInput === 'confirmPin'}
            style={{animation: `${confirmPinAnimation} ${invalidInputAnimationDuration}s ease-in-out`}}
          />
        }
      </Row>
      <Row className="my-4 m-0">
        <Numpad handleChange={handleNumpadInput} />
      </Row>
      <Row className="m-0">
        <PrimaryButton
          text={t.messages['app.confirm']}
          onClick={isSubmitting ? () => null : handleSubmit}
          isEnabled={validateInput() && !isSubmitting}
          color="yellow"
        />
        <PrimaryButton
          text={t.messages['app.cancel']}
          onClick={goBack}
          color="white"
          outline={true}
          border="lightGrey"
        />
      </Row>
    </TransitionView>
  )
}