import React, { useEffect } from 'react';
import { Card } from 'reactstrap';
import { TopNav } from 'components/layout/TopNav';
import { colors } from 'config/colors';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { getBackgroundColor } from 'helpers/utils';

const styles = {
  body: {
    height: '100vh',
    padding: '2vw'
  },
  appCard: {
    height: '100%',
    overflow: 'hidden',
    borderRadius: '3vw',
    backgroundColor: colors.bgColor,
    border: 'none'
  },
  main: {
    height: '100%',
    overflow: 'hidden',
    padding: '3vw'
  }
};

export const AppLayout = ({ children }) => {
  const path = useLocation().pathname;
  const newPath = path.split('/')[1];
  
  let navigate = useNavigate();

  var timer = null;

  const resetAnimation = () => {
    var el = document.getElementById('timerAnimation');
    if(el){
      el.style.animation = 'none';
      setTimeout(function () {
        el.style.animation = `fade-out-bg ${process.env.REACT_APP_INACTIVITY_TIMER}s linear`;
      }, 1);
    }
  };

  // event listeners to detect user interaction with the screen
  const detectActivity = () => {
    document.addEventListener('mousedown', resetInactivityTimer);
    document.addEventListener('mousemove', resetInactivityTimer);
    document.addEventListener('touchstart', resetInactivityTimer);
    document.addEventListener('scroll', resetInactivityTimer);
    document.addEventListener('keydown', resetInactivityTimer);
  };
  
  // set the inactivity timer to navigate to the home view after x seconds have passed
  const resetInactivityTimer = () => {
    if(timer !== null) clearTimeout(timer);
    resetAnimation();
    timer = setTimeout(() => {
      navigate(process.env.REACT_APP_BASE_URL);
      resetAnimation();
    }, process.env.REACT_APP_INACTIVITY_TIMER * 1000);

    // TODO: clear any stored user data
  };

  // initiate the inactivity detection on component mount
  useEffect(() => {
    resetInactivityTimer();
    detectActivity();
  }, []);
  
  return (
    <div 
      style={{
        ...styles.body,
        // backgroundColor: `${getBackgroundColor(newPath)}`,
        backgroundColor: colors.bgColor,
      }}
    >
      {path === process.env.REACT_APP_BASE_URL
        ?
          <div
            style={{
              position: 'absolute',
              top: '0',
              left: '0',
              width: '100%',
              height: '100%',
              backgroundColor: `${getBackgroundColor(newPath)}`
            }}
          ></div>
        :
          <div
            id="timerAnimation"
            style={{
              position: 'absolute',
              top: '-10%',
              left: '0',
              width: '100%',
              height: '110%',
              background: `linear-gradient(0deg, ${getBackgroundColor(newPath)} 90%, ${colors.bgColor} 100%)`,
              animation: `fade-out-bg ${process.env.REACT_APP_INACTIVITY_TIMER}s linear`
            }}
          ></div>
      }
      <Card style={styles.appCard}>
        <header>
          <TopNav />
        </header>
        <main style={styles.main}>
          {children}
        </main>
      </Card>
    </div>
  );
};