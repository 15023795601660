import React from 'react';
import ReactDom from 'react-dom';
import { getBackgroundColor } from '../helpers/utils';

const getColor = () =>{
  const url = window.location.pathname;
  const location = url.split('/')[1];
  return getBackgroundColor(location)
};

const styles = {
  container: {
    position: 'absolute',
    top: '0',
    left: '0',
    zIndex: '99',
    height: '100vh',
    width: '100vw',
    backgroundColor: getColor(),
    padding: '2vw'
  }
}

export const Portal = ({ children, isOpen }) => {

  if (isOpen === false) return null;

  return ReactDom.createPortal(
    <div style={styles.container}>
      {children}
    </div>,
    document.getElementById('portal')
  )
}