import React from 'react';
import { Row, Col } from 'reactstrap';

const styles = {
  container: {
    transition: '0.3s ease-in-out'
  },
  label: {
    fontSize: '1.6em',
    fontWeight: '600'
  },
  inputChar: {
    borderBottom: '2px solid #000',
    width: '70%',
    height: '50px',
    textAlign: 'center',
    fontSize: '1.6em',
    fontWeight: '600'
  }
};

export const Input = ({ label, size, type, value, isFocused, style }) => {
  
  return(
    <div
      className="my-4"
      style={{
        ...styles.container,
        opacity: `${isFocused ? '1' : '0.2'}`,
        transform: `${isFocused ? 'scale(1)' : 'scale(0.9)'}`,
        ...style
      }}
    >
      <p style={styles.label}>{label}</p>
      <Row className="justify-content-center">
        {Array.from(Array(size).keys()).map((i) =>
          <Col xs="1" className="p-0 d-flex justify-content-center" key={i}>
            <div style={styles.inputChar}>
              {/* if type is password replace characters with asterisk else show input characters */}
              {type === 'password'
              ?
                value[i] ? '*' : ' '
              :
                value[i] ?? ' '
              }
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};