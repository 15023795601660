import React from 'react';
import { CustomQrCode } from 'components/common/CustomQrCode';
import { MenuButton } from 'components/common/MenuButton';
import { Row, Col } from 'reactstrap';
import { useNavigate } from 'react-router';
import { TransitionView } from 'components/common/transition-view';
import { useRouterTransition } from 'hooks/useRouterTransition';
import { useIntl } from 'react-intl';
import { ReactComponent as Lokker } from 'assets/images/icons/Lokker.svg';
import { ReactComponent as Depositar } from 'assets/images/icons/Depositar.svg';
import { ReactComponent as LokkerAbrir } from 'assets/images/icons/LokkerAbrir.svg';

const styles= {
  row: {
    height: '40%'
  },
  middleRow: {
    height: '20%',
    fontSize: '3vw'
  },
  boldHintText: {
    fontWeight: '600',
    marginBottom: '-10vw'
  }
};

export const YellowZoneHome = () => {
  const t = useIntl();
  const navigate = useNavigate();
  let { transitionType, handleTransition } = useRouterTransition(navigate);

  const navTo = (url) =>{
    handleTransition(url)
  };

  return(
    <TransitionView animation="fade-down" transitionType={transitionType}>
      <Row style={styles.row} className="align-items-center">
        <Col className="d-flex justify-content-center w-100">
          <CustomQrCode value="https://yellow.demo.lokk.dev/"/>
        </Col>
      </Row>
      <Row style={styles.middleRow} className="justify-content-center align-items-start">
        <span style={styles.boldHintText}>{t.messages['app.qrCodeHint']}</span>
        <span>{t.messages['app.qrCodeHint2']}</span>
        <span style={styles.boldHintText}>{t.messages['app.or']}</span>
      </Row>
      <Row style={styles.row} className="align-items-center">
        <Col>
          <MenuButton
            text={t.messages['app.requestLocker']}
            subtext={t.messages['app.requestLockerSub']}
            icon={<Lokker height="40" width="40" />}
            onClick={() => navTo('lokker-type')}
          />
          <MenuButton
            text={t.messages['app.myLockers']}
            subtext={t.messages['app.myLockersSub']}
            icon={<LokkerAbrir height="40" width="40"/>}
            onClick={() => navTo('access-my-lokkers')}
          />
          <MenuButton
            text={t.messages['app.pickNcollect']}
            subtext={t.messages['app.pickNcollectSub']}
            icon={<Depositar height="40" width="40"/>}
            onClick={() => navTo('pick-and-collect')}
          />
        </Col>
      </Row>
    </TransitionView>
  );
};