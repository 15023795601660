import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { IntlProvider } from 'react-intl';
import english from 'translations/en-US.json';
import portuguese from 'translations/pt-PT.json';
import spanish from 'translations/es-ES.json';
import { store } from 'redux/storeConfig/store';
import { Provider } from 'react-redux';
import { useSelector } from 'react-redux';

const WrapApp = () => {
  const locale = useSelector(state => state.language);

  const getLanguage = () => {
    if(locale === 'pt-PT') return portuguese;
    else if(locale === 'es-ES') return spanish;
    else if(locale === 'en-US') return english;
  };

  return(
    <Provider store={store}>
      <IntlProvider locale={locale} messages={getLanguage()}>
        <App />
      </IntlProvider>
    </Provider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <WrapApp />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
